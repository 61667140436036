import { useMutation, UseMutationOptions } from 'react-query'
import { useUser } from '~context/user'

export const useCancelReservation = (
  reservationId: string,
  itineraryId: string,
  options?: UseMutationOptions
) => {
  const { user } = useUser()
  const url = `${process.env.GATSBY_STRAPI_API_URL}/reservations/cancel`

  return useMutation(async () => {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        reservation_id: reservationId,
        itinerary_id: itineraryId,
        userId: user.id,
      }),
    })

    // Handle error specific to this endpoint
    if (!res.ok) {
      const data = await res.json()
      throw new Error(data.data.Message)
    }
  }, options)
}
