import React, { useState, useEffect, useMemo } from 'react'
import debounce from 'lodash/debounce'
import querystring from 'query-string'
import { useLocation, RouteComponentProps } from '@reach/router'
import {
  Text,
  Progress,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from '@chakra-ui/react'
import { HiOutlineSearch } from 'react-icons/hi'
import { navigate } from 'gatsby-link'

import Seo from '~components/shared/seo'
import Heading from '~components/shared/heading'
import Input from '~components/shared/input'
import ContentBox from '~components/shared/content-box'
import EventsTable from '~components/pages/events/events-table'
import { useScroll } from '~context/scroll-provider'
import { eventsIndex } from '~utils/algolia'

const EventsPage: React.FC<RouteComponentProps> = () => {
  const { search } = useLocation()
  const { isBottom } = useScroll()
  const { query } = querystring.parse(search) as { query: string | undefined }

  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [totalHits, setTotalHits] = useState(0)
  const [searchTime, setSearchTime] = useState(0)
  const [hitsPerPage, setHitsPerPage] = useState(20)

  const resultsTextColor = useColorModeValue('gray.500', 'gray.400')

  const searchEvents = async () => {
    setLoading(true)
    const { hits, nbHits, processingTimeMS } = await eventsIndex.search(
      query || '',
      {
        hitsPerPage,
        facetFilters: ['isActive: true'],
      }
    )
    // @ts-ignore
    setEvents(hits)
    setLoading(false)
    setTotalHits(nbHits)
    setSearchTime(processingTimeMS / 100)
  }

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target
    if (value === '') navigate(`/events`)
    else navigate(`/events?${querystring.stringify({ query: value })}`)
    setHitsPerPage(20)
  }

  const debouncedSearchText = useMemo(
    () => debounce(handleSearchTextChange, 200),
    []
  )

  useEffect(() => {
    searchEvents()
  }, [query, hitsPerPage])

  useEffect(() => {
    if (isBottom && hitsPerPage < totalHits) setHitsPerPage(hitsPerPage + 20)
  }, [isBottom])

  return (
    <>
      <Seo title="Events" />
      <Heading>Events</Heading>
      <InputGroup mb={4} variant="filled">
        <InputLeftElement mx={1}>
          <HiOutlineSearch size={18} />
        </InputLeftElement>
        <Input
          pl={10}
          placeholder="Search"
          defaultValue={query}
          onChange={debouncedSearchText}
        />
      </InputGroup>
      <ContentBox p={0} pt={4} pb={8}>
        <Text
          ml={6}
          my={2}
          fontSize="sm"
          fontWeight="semibold"
          color={resultsTextColor}
        >
          {`${
            events.length
          } of ${totalHits.toLocaleString()} active events (${searchTime} seconds)`}
        </Text>
        <EventsTable events={events} />
        <Progress h="2px" isIndeterminate={loading} colorScheme="red" />
      </ContentBox>
    </>
  )
}

export default EventsPage
