import React, { useMemo } from 'react'
import { RouteComponentProps } from '@reach/router'
import {
  Box,
  Text,
  Grid,
  GridItem,
  AvatarGroup,
  Avatar,
  Divider,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import loadable from '@loadable/component'

import Seo from '~components/shared/seo'
import Heading from '~components/shared/heading'
import ContentBox from '~components/shared/content-box'
import ContentBoxTitle from '~components/shared/content-box-title'
import EventOrders from './event-orders'
import EventTickets from './event-tickets'
import { useFestivalBySlugQuery } from '~graphql/generated'

const EventMap = loadable(() => import('./event-map'))

interface Props extends RouteComponentProps {
  slug?: string
}

const EventsDetailsPage: React.FC<Props> = ({ slug }) => {
  const { data, loading } = useFestivalBySlugQuery({ variables: { slug } })
  const festival = useMemo(() => data?.festivals?.[0], [data])

  return (
    <>
      <Seo title={`Event ${slug}`} />
      <Heading isLoading={loading}>
        {festival?.name || 'Event Not Found'}
      </Heading>
      <Grid
        gap={[4, 6]}
        templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']}
      >
        <GridItem>
          <ContentBox h="100%" isLoading={loading}>
            <ContentBoxTitle mb={2}>Location</ContentBoxTitle>
            <Box fontSize="15px">
              <Text fontWeight="semibold">{festival?.venue?.name}</Text>
              <Text>{festival?.address}</Text>
              <Text>
                {festival?.city}, {festival?.state}
              </Text>
            </Box>
            <Divider my={4} />
            <Grid gap={[2, 4]} templateColumns={['repeat(2, 1fr)']}>
              {festival?.start && (
                <GridItem>
                  <ContentBoxTitle mb={2}>Start</ContentBoxTitle>
                  <Text fontSize="15px">
                    {format(new Date(festival.start), `MMMM do yyyy`)}
                  </Text>
                  <Text fontSize="15px" fontWeight="bold">
                    {format(new Date(festival.start), 'h:mm aaa')}
                  </Text>
                </GridItem>
              )}
              {festival?.end && (
                <GridItem>
                  <ContentBoxTitle mb={2}>End</ContentBoxTitle>
                  <Text fontSize="15px">
                    {format(new Date(festival.end), `MMMM do yyyy`)}
                  </Text>
                  <Text fontSize="15px" fontWeight="bold">
                    {format(new Date(festival.end), 'h:mm aaa')}
                  </Text>
                </GridItem>
              )}
            </Grid>
            <Divider my={4} />
            <ContentBoxTitle mb={2}>Likes</ContentBoxTitle>
            <Box>
              <AvatarGroup size="md" max={5}>
                {festival?.interested_attendees?.map(a => (
                  <Avatar
                    key={a?.id}
                    src={a?.profile_img?.url}
                    name={`${a?.first_name} ${a?.last_name}`}
                  />
                ))}
              </AvatarGroup>
            </Box>
          </ContentBox>
        </GridItem>
        <GridItem>
          <ContentBox p={0} minH="250px">
            {festival?.lat && festival?.lng && (
              <EventMap lat={festival.lat} lng={festival.lng} />
            )}
          </ContentBox>
        </GridItem>
      </Grid>

      <ContentBox hasMargin p={0} py={6}>
        <ContentBoxTitle ml={6}>Orders</ContentBoxTitle>
        <EventOrders festivalId={festival?.id} />
      </ContentBox>

      {festival?.external_id && (
        <ContentBox p={0} py={[4, 6]} hasMargin>
          <ContentBoxTitle ml={[4, 6]}>Ticket listings</ContentBoxTitle>
          <EventTickets external_id={festival.external_id} />
        </ContentBox>
      )}
    </>
  )
}

export default EventsDetailsPage
