import { useQuery, UseQueryOptions } from 'react-query'

import * as http from '~utils/http-client'

export interface IUserData {
  id: number
  created_at: Date
  first_name: string
  last_name: string
  email: string
  membership: string
  phone?: string
  city?: string
  state?: string
  zipcode?: string
  lat?: number
  lng?: number
}

export function useMapData(
  options?: UseQueryOptions<IUserData[], Error, IUserData[], 'map-data'>
) {
  const url = `${process.env.GATSBY_STRAPI_API_URL}/users/admin-fetch`

  return useQuery(
    'map-data',
    async () => {
      const { promise } = http.customFetch(url)
      const res = (await promise) as IUserData[]
      return res
    },
    options
  )
}
