import React from 'react'
import {
  Box,
  Text,
  Center,
  Progress,
  AspectRatio,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  IconButton,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react'
import { RiEyeLine } from 'react-icons/ri'

import PlaceholderImage from '~components/shared/placeholder-image'
import EmptyList from '~images/illustrations/empty-list.svg'
import { useFestivalTickets } from '~hooks/queries'

interface Props {
  external_id: string
}

const EventTickets: React.FC<Props> = ({ external_id }) => {
  const { data, isLoading, isFetched } = useFestivalTickets(external_id)
  const tickets = data || []

  return (
    <>
      {tickets.length > 0 && (
        <Box overflow="auto">
          <Table size="sm" variant="striped">
            <Thead>
              <Tr>
                <Th>Section</Th>
                <Th>Row</Th>
                <Th>Seats</Th>
                <Th>Delivery</Th>
                <Th isNumeric>Qty</Th>
                <Th isNumeric>Dollars cost</Th>
                <Th isNumeric>Credits cost</Th>
              </Tr>
            </Thead>
            <Tbody>
              {tickets.map((ticket, i) => (
                <Tr key={i}>
                  <Td>
                    <HStack spacing={0}>
                      <Popover trigger="hover" placement="bottom-start">
                        <PopoverTrigger>
                          <IconButton
                            size="xs"
                            variant="ghost"
                            colorScheme="purple"
                            aria-label="section-view"
                            icon={<RiEyeLine size="18px" />}
                          />
                        </PopoverTrigger>
                        <PopoverContent
                          w="280px"
                          rounded="xl"
                          overflow="hidden"
                        >
                          <PopoverHeader fontSize="sm" fontWeight="semibold">
                            {ticket.section}
                          </PopoverHeader>
                          <PopoverBody p={0}>
                            <AspectRatio ratio={3 / 2} width="100%">
                              <Image
                                src={ticket.img?.sm}
                                fallback={<PlaceholderImage />}
                              />
                            </AspectRatio>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                      <Box pl={2}>
                        <Text>{ticket.section}</Text>
                      </Box>
                    </HStack>
                  </Td>
                  <Td>{ticket.row}</Td>
                  <Td>
                    {ticket.lowSeat} - {ticket.highSeat}
                  </Td>
                  <Td>{ticket.delivery}</Td>
                  <Td isNumeric>{ticket.quantity}</Td>
                  <Td isNumeric>${ticket.price}</Td>
                  <Td isNumeric>{ticket.priceCredits}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}

      <Progress h="2px" colorScheme="brand" isIndeterminate={isLoading} />

      {isFetched && tickets.length === 0 && (
        <Center flexDir="column" fontSize="15px" mt={6}>
          <Image src={EmptyList} w="150px" />
          <Text mt={4} fontSize="sm" fontWeight="semibold" fontStyle="italic">
            No listings available
          </Text>
        </Center>
      )}
    </>
  )
}

export default EventTickets
