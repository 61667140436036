import React from 'react'
import { Router } from '@reach/router'

import EventsPage from '~components/pages/events/events-page'
import EventsDetailsPage from '~components/pages/events/events-detail-page'

const EventsPageRouter = () => {
  return (
    <Router basepath="/events">
      <EventsPage path="/" />
      <EventsDetailsPage path="/:slug" />
    </Router>
  )
}

export default EventsPageRouter
