import React from 'react'
import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Image,
  HStack,
  Skeleton,
  AspectRatio,
  Link,
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import { format } from 'date-fns'

import PlaceholderImage from '~images/event-placeholder.jpg'
import { useDatasourcesQuery } from '~graphql/generated'

interface Props {
  events: any[]
}

const EventsTable: React.FC<Props> = ({ events }) => {
  const { data } = useDatasourcesQuery()

  const getDatasource = (sourceId?: number) => {
    return data?.datasources?.filter(d => d?.id === sourceId?.toString())?.[0]
  }

  return (
    <Box overflow="auto">
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th fontWeight="extrabold">Event</Th>
            <Th minW="150px">Source</Th>
            <Th minW="140px">Date</Th>
            <Th>Categories</Th>
            <Th w="160px">Location</Th>
          </Tr>
        </Thead>
        <Tbody>
          {events.map(event => {
            const image =
              event.cover_image?.url ||
              event.festivalcategories[0]?.cover_image?.url ||
              PlaceholderImage

            return (
              <Tr key={event.id} fontSize="sm">
                <Td minW="350px" maxW="500px">
                  <Link as={GatsbyLink} to={`/events/${event.slug_name}`}>
                    <HStack spacing={4}>
                      <AspectRatio
                        ratio={3 / 2}
                        minW="96px"
                        rounded="xl"
                        overflow="hidden"
                      >
                        <Image fallback={<Skeleton />} src={image} />
                      </AspectRatio>
                      <Box>
                        <Text fontWeight="semibold" noOfLines={2}>
                          {event.name}
                        </Text>
                      </Box>
                    </HStack>
                  </Link>
                </Td>
                <Td>{getDatasource(event.datasource)?.name || 'N/A'}</Td>
                <Td>{format(new Date(event.start), 'MMM d, yyyy')}</Td>
                <Td>
                  {event.festivalcategories.map((c: any) => c.name).join(', ')}
                </Td>
                <Td>
                  <Text>
                    {event.city
                      ? `${event.city}, ${event.state}`
                      : event.location}
                  </Text>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Box>
  )
}

export default EventsTable
