import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const PlaceholderImage: React.FC = () => {
  return (
    <StaticImage
      layout="fullWidth"
      placeholder="blurred"
      alt="Festival pass placeholder image"
      src="../../images/event-placeholder.jpg"
      style={{ height: '100%', width: '100%', objectFit: 'cover' }}
    />
  )
}

export default PlaceholderImage
