import React, { useState, useMemo } from 'react'
import { Progress, Center, Text } from '@chakra-ui/react'

import OrdersTable from '~components/pages/orders/table'
import OrderDetailsDrawer from '~components/pages/orders/order-details-drawer'
import {
  TicketOrderFieldsFragment,
  useTicketOrdersByFestivalQuery,
} from '~graphql/generated'

interface Props {
  festivalId?: string
}

const EventOrders: React.FC<Props> = ({ festivalId }) => {
  const { data, loading } = useTicketOrdersByFestivalQuery({
    skip: !festivalId,
    variables: { id: festivalId },
  })

  const [selectedOrderId, setSelectedOrderId] = useState<string>()
  const selectedOrder = useMemo(
    () =>
      data?.ticketorders?.find(order => order?.id === selectedOrderId) ||
      undefined,
    [selectedOrderId, data]
  )

  const onOrderClick = (orderId: string) => {
    setSelectedOrderId(orderId)
  }

  const onDrawerClose = () => setSelectedOrderId(undefined)

  return (
    <>
      <OrdersTable
        orders={(data?.ticketorders as TicketOrderFieldsFragment[]) || []}
        handleOrderClick={onOrderClick}
      />
      {loading && (
        <Progress h="2px" isIndeterminate={loading} colorScheme="brand" />
      )}
      {data && data.ticketorders?.length === 0 && (
        <Center mt={16} mb={12}>
          <Text fontWeight="semibold">This event has no orders yet.</Text>
        </Center>
      )}
      <OrderDetailsDrawer
        order={selectedOrder}
        isOpen={!!selectedOrder}
        onClose={onDrawerClose}
      />
    </>
  )
}

export default EventOrders
