import { useQuery, UseQueryOptions } from 'react-query'
import * as http from '~utils/http-client'

interface ITicket {
  id: string
  price: number
  quantity: number
  splits: number[]
  section: string
  row: string
  lowSeat: string
  highSeat: string
  delivery: string
  onHateDate: Date
  priceCredits: number
}

interface ITicketRes {
  id: string
  name: string
  canonicalName: string
  index: number
  levelId: string
  levelName: string
  img?: { sm: string; lg: string }
  tickets: ITicket[]
}

interface IParsedTicket extends ITicket {
  canonicalName: string
  img?: { sm: string; lg: string }
}

const parseTicketsSection = (tickets: ITicketRes[]): IParsedTicket[] => {
  return tickets
    .map(({ canonicalName, img, tickets }) => {
      return tickets.map(ticket => {
        return { ...ticket, canonicalName, img }
      })
    })
    .flat()
}

export const useFestivalTickets = (
  external_id?: string,
  options?: UseQueryOptions<
    IParsedTicket[],
    Error,
    IParsedTicket[],
    [string, string | undefined]
  >
) => {
  const url = `${process.env.GATSBY_STRAPI_API_URL}/tickets/selectaticket/${external_id}`

  return useQuery(
    ['tickets', external_id],
    async () => {
      const { promise } = http.customFetch(url)
      const res = (await promise) as ITicketRes[]
      return parseTicketsSection(res)
    },
    { enabled: !!external_id, ...options }
  )
}
